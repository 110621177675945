import React, { useState, useEffect } from 'react';
import releaseNotes from './release-notes.json';

interface ReleaseNote {
  version: string;
  date: string;
  changes: string[];
}

export const ReleaseNotes: React.FC = () => {
  const [selectedVersion, setSelectedVersion] = useState('all');

  useEffect(() => {
    // Load the release note data from the JSON file
    // This is not necessary in this case, as the data is already available
  }, []);

  return (
    <div>
      <h1>Release Notes</h1>

      <select value={selectedVersion} onChange={(e) => setSelectedVersion(e.target.value)}>
        <option value="all">All Versions</option>
        {Object.keys(releaseNotes).map((version) => (
          <option key={version} value={version}>
            {version}
          </option>
        ))}
      </select>
      <br />
      <br />
      <table border={2} width="100%" cellPadding="5px">
        <thead>
          <tr>
            <th align="center">Version</th>
            <th align="center">Date</th>
            <th align="center">Changes</th>
          </tr>
        </thead>

        <tbody>
          {selectedVersion === 'all'
            ? Object.values(releaseNotes).flat().map((note, index) => (
                <tr key={index}>
                  <td>{note.version}</td>
                  <td>{note.date}</td>
                  <td>
                    <ul>
                      {note.changes.map((change, changeIndex) => (
                        <li key={changeIndex}>{change}</li>
                      ))}
                    </ul>
                  </td>
                </tr>
              ))
            : (releaseNotes[selectedVersion] as ReleaseNote[]).map((note, index) => (
                <tr key={index}>
                  <td>{note.version}</td>
                  <td>{note.date}</td>
                  <td>
                    <ul>
                      {note.changes.map((change, changeIndex) => (
                        <li key={changeIndex}>{change}</li>
                      ))}
                    </ul>
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReleaseNotes;