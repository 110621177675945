import { FunctionComponent, ReactNode, createContext, useState } from 'react';

import { useContext } from 'react';

interface User {
  name: string;
  email: string;
}

interface AppContextProps {
  user?: User;
  setUser?: (user: User) => void;
  config?: Record<string, any>;
}

interface AppContextProviderProps {
  children: ReactNode;
  config?: Record<string, any>;
}

const AppContext = createContext<AppContextProps>({});

export const AppContextProvider: FunctionComponent<AppContextProviderProps> = (
  props
) => {
  const [authenticatedUser, setAuthenticatedUser] = useState<User>();

  return (
    <AppContext.Provider
      value={{
        user: authenticatedUser,
        setUser: setAuthenticatedUser,
        config: props.config
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
