import './index.css';

import { Amplify, Auth } from 'aws-amplify';

import App from './App';
import { AppContextProvider } from './AppContext';
import { Authenticate } from './Authenticate';
import { NorthStarThemeProvider } from '@aws-northstar/ui';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

const config = require('./amplifyConfig');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const amplifyConfig = Amplify.configure(config.default);

root.render(
  <React.StrictMode>
    <NorthStarThemeProvider>
      <AppContextProvider config={amplifyConfig}>
        <Authenticate>
          <App />
        </Authenticate>
      </AppContextProvider>
    </NorthStarThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
