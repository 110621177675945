//declare var awsConfig;
var awsConfig;

let config = undefined;

if (awsConfig && awsConfig.aws_project_region) {
  // deployed in cloudfront
  console.log('cloudfront');
  config = awsConfig;
} else {
  // run locally
  console.log('local');
  config = {
    // uncomment for prod
    // "aws_project_region": "us-west-2",
    // "aws_cognito_identity_pool_id": "us-west-2:19234546-d314-45dd-beb3-c166b02417c7",
    // "aws_cognito_region": "us-west-2",
    // "aws_user_pools_id": "us-west-2_YM2TYRkzA",
    // "aws_user_pools_web_client_id": "5aehpdafe5h6uokhpj4as26tht",
    // "oauth": {
    //     "domain": "formulate-prod.auth.us-west-2.amazoncognito.com",
    //     "scope": [
    //         "phone",
    //         "email",
    //         "openid",
    //         "profile",
    //         "aws.cognito.signin.user.admin"
    //     ],
    //     "redirectSignIn": "https://prod.formulate.wwso.aws.dev/",
    //     "redirectSignOut": "https://prod.formulate.wwso.aws.dev/",
    //     "responseType": "code"
    // },
    // "aws_user_files_s3_bucket_region": "us-west-2",
    // "aws_user_files_s3_bucket": "formulate-stack-inputbucket08d572f4-1ceswv6w4pq93",
    // "aws_output_ddb_table_name": "formulate-stack-outputtableD21D09E4-DVYHL6BDUBZ"    
    // uncomment for test
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:52ebd2ca-7379-418c-b75a-1e4bbbbcbb00",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_WjIqCBWLo",
    "aws_user_pools_web_client_id": "63sbheq5npimtu5p9dgf1mvqd3",
    "oauth": {
        "domain": "formulate-test.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://test.formulate.wwso.aws.dev/",        
        "redirectSignOut": "https://test.formulate.wwso.aws.dev/",        
        "responseType": "code"
    },
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_user_files_s3_bucket": "formulate-stack-test-inputbucket08d572f4-1ne7jli8pp50y",
    "aws_output_ddb_table_name": "formulate-stack-test-outputtableD21D09E4-LNZG4LYEM7S9"
  };
}

export default config;
