import * as ddb from '@aws-sdk/client-dynamodb';
import * as s3 from '@aws-sdk/client-s3';

import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';

import { Auth } from 'aws-amplify';
import { Button } from '@cloudscape-design/components';
import { DynamoDBDocument } from '@aws-sdk/lib-dynamodb';
import { Table } from '@aws-northstar/ui/components';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { useAppContext } from './AppContext';

export const Report: FunctionComponent = () => {
  const { user, config } = useAppContext();

  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Auth.currentCredentials().then((credentials) => {
      DynamoDBDocument.from(
        new ddb.DynamoDBClient({
          region: config?.aws_project_region,
          credentials,
        })
      )
        .query({
          TableName: config?.aws_output_ddb_table_name,
          ReturnConsumedCapacity: 'TOTAL',
          Limit: 50,
          KeyConditionExpression: 'requestor = :kv0',
          IndexName: 'requestor',
          ExpressionAttributeValues: { ':kv0': user?.email },
          Select: 'ALL_PROJECTED_ATTRIBUTES',
          ScanIndexForward: false, // Sort in descending order
        })
        .then((data) => {
          setIsLoading(false);
          setItems(
            data.Items
              ? data.Items.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()).map((item) => {
                  return {
                    id: item.id,
                    fileName: item.fileName,
                    bucket: item.bucket,
                    createdDate: item.createdDate,
                  };
                })
              : []
          );
        });
    });
  }, [user, config]);

  const downloadFile = useCallback(
    (objectKey: string, bucket: string) => {
      Auth.currentCredentials().then((credentials) => {
        const s3Client = new s3.S3Client({
          region: config?.aws_project_region,
          credentials
        });
        const command = new s3.GetObjectCommand({
          Bucket: bucket,
          Key: objectKey
        });
        getSignedUrl(s3Client, command).then((url) =>
          window.open(url, '_blank')
        );
      });
    },
    [config]
  );

  const schema = useMemo(
    () => [
      { header: 'Id', id: 'id', width: 50, cell: (item: any) => item.id },
      {
        header: 'File name',
        id: 'fileName',
        width: 200,
        cell: (item: any) => item.fileName
      },
      {
        header: 'Created time',
        id: 'createDateTime',
        width: 200,
        cell: (item: any) =>
          new Date(item.createdDate).toLocaleString(undefined, {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
          }),
        sortingField: 'createdDate'
      },
      {
        header: 'Download link',
        id: 'link',
        width: 200,
        cell: (item: any) => (
          <Button
            variant='primary'
            iconName='download'
            onClick={() => downloadFile(item.fileName, item.bucket)}
          >
            Download
          </Button>
        )
      }
    ],
    []
  );

  return (
    <Table
      columnDefinitions={schema}
      header='Your reports'
      loading={isLoading}
      items={items}
      disableRowSelect={true}
      //   disableSettings={true}
    />
  );
};
