import { FileType, FileUpload, FormRenderer } from '@aws-northstar/ui';

import Flashbar from '@cloudscape-design/components/flashbar';
import { Storage } from 'aws-amplify';
import { componentTypes } from '@aws-northstar/ui';
import dataTypes from '@data-driven-forms/react-form-renderer/data-types';
import { useAppContext } from './AppContext';
import { useAppLayoutContext } from '@aws-northstar/ui/components/AppLayout';
import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { validatorTypes } from '@aws-northstar/ui';

const FileUploadComponent = ({
  name,
  label,
  description,
  input,
  onChange
}: any) => {
  const handleOnChange = useCallback(
    (files: FileType[]) => {
      if (files && files.length > 0) {
        if (onChange) {
          onChange(files);
          console.log(files);
        }

        input.onChange(files.map((f: any) => f));
      }
    },
    [input, onChange]
  );

  return (
    <FileUpload
      controlId={name}
      onChange={handleOnChange}
      label={label}
      description={description}
    />
  );
};

const schema = {
  header: 'VMware migration evaluation and licensing tool',
  description:
    'This tool is designed for RVTools files saved in XLSX form, which must have a vInfo worksheet. A vCPU and vDisk worksheet is optionally required if missing information in the vInfo sheet. This tool will also process custom files. If this is desired, name the first sheet "Custom", delete all other sheets unless they also list VMs you wish to process. Columns on these sheets must also be named accordingly. Custom file processing must have columns named  VM, OS, CPUs, MemoryMB, StorageUsedGB Optionally CPUPeak - a whole number between 1 and 100, Environment, SQL Edition, ProvisionedStorageGB, Group. Group is used by Bulk Upload template. Make sure to note units! Memory must be MB and Disk in GB!  Insert a column and add formula to convert if necessary - and dont forget this column must be named correctly so it will be the one which is processed.',
  fields: [
    {
      component: componentTypes.CUSTOM,
      name: 'file',
      label: 'VMware Input file',
      description: 'RVTools file, in xlsx format and unencrypted.',
      CustomComponent: FileUploadComponent,
      onchange: console.log
    },            
    {
      component: componentTypes.SELECT,
      name: 'region',
      label: 'Region',
      description: 'Select region to use for VMC pricing',
      isRequired: false,
      options: [
        { label: 'US East (N. Virginia)', value: 'us-east-1' },
        { label: 'US East (Ohio)', value: 'us-east-2' },
        { label: 'US West (N. California)', value: 'us-west-1' },
        { label: 'US West (Oregon)', value: 'us-west-2' },
        { label: 'Canada (Central)', value: 'ca-central-1' },
        { label: 'South America (Sao Paulo)', value: 'sa-east-1' },
        { label: 'EU (Frankfurt)', value: 'eu-central-1' },
        { label: 'Europe (Zurich)', value: 'eu-central-2' },
        { label: 'EU (Ireland)', value: 'eu-west-1' },
        { label: 'EU (London)', value: 'eu-west-2' },
        { label: 'EU (Paris)', value: 'eu-west-3' },
        { label: 'EU (Stockholm)', value: 'eu-north-1' },
        { label: 'EU (Milan)', value: 'eu-south-1' },
        { label: 'Asia Pacific (Mumbai)', value: 'ap-south-1' },
        { label: 'APAC (Hyderabad)', value: 'ap-south-2' },
        { label: 'Asia Pacific (Singapore)', value: 'ap-southeast-1' },
        { label: 'Asia Pacific (Sydney)', value: 'ap-southeast-2' },
        { label: 'Asia Pacific (Melbourne)', value: 'ap-southeast-4' },
        { label: 'Asia Pacific (Tokyo)', value: 'ap-northeast-1' },
        { label: 'Asia Pacific (Seoul)', value: 'ap-northeast-2' },
        { label: 'Asia Pacific (Osaka)', value: 'ap-northeast-3' },
        { label: 'Asia Pacific (Hong Kong)', value: 'ap-east-1' },        
        { label: 'Africa (Cape Town)', value: 'af-south-1' },
        { label: 'Middle East (Bahrain)', value: 'me-south-1' }              
      ],
      validate: [
        {
          type: validatorTypes.REQUIRED
        }
      ]
    },
    {
      component: componentTypes.TEXT_FIELD,
      name: 'customer',
      label: 'Customer name',
      description: 'The customer name will appear on the generated report',
      isRequired: false,
      dataType: dataTypes.STRING
    },
    {
      component: componentTypes.TEXT_FIELD,
      name: 'opportunity',
      label: 'SFDC Opportunity URL',
      description: 'The URL for the opportunity in Salesforce',
      isRequired: false,
      dataType: dataTypes.STRING
    }
  ],
  canReset: true,
  canCancel: false
};

export const Home = () => {
  const appLayout = useAppLayoutContext();
  const { user } = useAppContext();

  return (
    <FormRenderer
      schema={schema}
      onSubmit={async (formValues) => {
        console.info(formValues);

        const file = formValues.file[0] as File;

        Storage.put(`${uuid()}/${file.name}`, file, {
          tagging: `requestor=${user?.email}&customer=${formValues.customer}&region=${formValues.region.value}&opportunity=${formValues.opportunity}`
        })
          .then((_) => {
            appLayout.setNotifications(
              <Flashbar
                stackItems={true}
                items={[
                  {
                    header:
                      'Form submitted successfully. Please expect a report in your inbox in 3-5 minutes. If more than 10 min has elapsed something has gone wrong.',
                    content:
                      'Potential causes for failure to generate a report: Missing values for one or multiple VMs, Excel file is in XLS format (XLSX required), Excel file is encrypted, etc.',
                    type: 'success',
                    dismissible: true,
                    onDismiss: () => appLayout.setNotifications([])
                  }
                ]}
              />
            );
          })
          .catch((error) => {
            console.error({ error });
            appLayout.setNotifications(
              <Flashbar
                stackItems={true}
                items={[
                  {
                    header: 'Failure',
                    content: 'An error occurred while submitting the form' + error,
                    type: 'error',
                    dismissible: true,
                    onDismiss: () => appLayout.setNotifications([])
                  }
                ]}
              />
            );
          });
      }}
    ></FormRenderer>
  );
};
