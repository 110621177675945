import { FunctionComponent, ReactNode, useEffect } from 'react';

import { Auth } from 'aws-amplify';
import Box from '@cloudscape-design/components/box';
import Button from '@cloudscape-design/components/button';
import Modal from '@cloudscape-design/components/modal';
import { useAppContext } from './AppContext';

interface AuthenticateProps {
  children: ReactNode;
}

export const Authenticate: FunctionComponent<AuthenticateProps> = (props) => {
  const { user, setUser } = useAppContext();

  useEffect(() => {
    if (user) {
      return;
    }
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        console.debug(currentUser);
        setUser?.({
          name: currentUser.attributes.name,
          email: currentUser.attributes.email
        });
      })
      .catch((_) => {
        // don't throw error for unauthenticated user
      });
  }, [user, setUser]);

  return user ? (
    <>{props.children}</>
  ) : (
    <Modal visible={true}>
      <Box textAlign={'center'}>
        <h3>AWS Formulate</h3>
        <p>Click "Sign in" to authenticate with Midway</p>
        <br></br>
        <Button
          onClick={() => {
            Auth.federatedSignIn({ customProvider: 'midway' });
          }}
          variant='primary'
        >
          Sign in
        </Button>
      </Box>
    </Modal>
  );
};
